import AllergyEntity from "@/entities/allergy-entity";
import IngredientItemGmoEntity from "@/entities/ingredient-item-gmo-entity";
import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";
import IngredientItemEntity, {IAmountItem} from "@/entities/ingredient-item-entity";
import CarryOver from "@/entities/carryover-entity";
import CarryOverHandler from "@/entities/concerns/carryover-handler";
import IngredientEntity, {IngredientUnitEntity} from "@/entities/ingredient-entity";

export interface IHasParent {
  getParentsRecursive(): Array<IHasParent>;
  readonly id: number;
}

export interface IRecipeComponent extends IHasParent {
  getAmountSumGram():number;
  getNameWithDisplayName():string;
  getAllChildren():IRecipeComponent[];
  getAllAllergens(): AllergyEntity[];
  getAllGmos(): IngredientItemGmoEntity[];
  getAllIngredientAdditivesRecursive(): IngredientAdditiveEntity[];
  findAmountRatioForIngredientOrderInTheRoot(targetItem:IAmountItem, calcWithReducedWeight: boolean, parentRatio?: number): number | null;
  getAllCarryoversRecursive(): CarryOver[];
  getAllChildrenRecursive(): IRecipeComponent[];
  getTotalConcentrationRate(forNutrition: boolean): number;

  readonly costPerKgComputed: number|null;
  readonly costSumComputed: number|null;
  readonly concentrationRatio: number;

  readonly items:IRecipeComponentItem[];
  readonly key: string|null;
  readonly isProduct: boolean;
  id: number;
  name: string;
  isYieldEnabled:boolean;
  yieldPercent:number|null;
  isYieldAffectToNutrition:boolean;
  isYieldAsConcentrationReduction: boolean;
  shouldYieldAsConcentrationReduction: boolean;
  getRateForIngredientOrder(calcWithReducedWeight: boolean):number;
}

export interface IRecipeComponentItem extends IHasParent {
  carryoverHandler:CarryOverHandler;
  getChildIngredient():IngredientEntity|null;

  getChildIngredientItemsRecursive():IngredientItemEntity[];
  getAdditivesRecursive():IngredientAdditiveEntity[];
  getAllAllergens():AllergyEntity[];
  getChildCarryoversRecursive(includeSelf?:boolean):CarryOver[];

  readonly costSumComputed:number|null;

  amount: number|null;
  unitType: RecipeComponentItemUnitType;
  unitTypeModel: RecipeComponentItemUnitTypeLabel | number; // numberの場合はingredientUnitId
  setIngredientUnit: (unit: IngredientUnitEntity) => void;
  item: IRecipeComponent|null;
  isCompositeSplitted:boolean|null;
  readonly key: string|null;
  readonly rowKey: number;
  readonly parent:IRecipeComponent;
  readonly carryovers:CarryOver[];
  readonly amountRatioInTheDirectParent: number;
}

export enum RecipeComponentItemUnitType {
  GRAM = 1,
  Kilogram = 2,
  Ton = 3,
  IngredientUnit = 99,
}
export type RecipeComponentItemUnitTypeLabel = 'g' | 'kg' | 't';
export const RecipeComponentItemUnitTypeDict = {
  [RecipeComponentItemUnitType.GRAM]: 'g',
  [RecipeComponentItemUnitType.Kilogram]: 'kg',
  [RecipeComponentItemUnitType.Ton]: 't',
}
export const RecipeComponentItemUnitTypeDictReverse = {
  'g': RecipeComponentItemUnitType.GRAM,
  'kg': RecipeComponentItemUnitType.Kilogram,
  't': RecipeComponentItemUnitType.Ton,
}

export function getAmountGram(
  unit: RecipeComponentItemUnitType,
  amount: number,
  ingredientUnit: IngredientUnitEntity | null,
  targetItem: IRecipeComponentItem
): number
{
  switch (unit) {
    case RecipeComponentItemUnitType.Kilogram:
      return amount * 1000;
    case RecipeComponentItemUnitType.Ton:
      return amount * 1000000;
    case RecipeComponentItemUnitType.IngredientUnit:
      if (!ingredientUnit)  {
        console.log(targetItem);
        throw new Error(`不正なデータ 単位が設定されていない原材料に対して、内訳で単位が使用されています。`);
      }
      return ingredientUnit.amountGram * amount;
    default:
      return amount;
  }
}

